<template>
  <div class="AiDatePicker-MonthYear">
    <button class="AiDatePicker-MonthYear-Button" @click="onPrev">
      <ai-icon
        v-if="customProps.isDesktop && displayLeftButton"
        class="AiDatePicker-MonthYear-Action"
        name="chevron-left"
        :size="12" />
    </button>

    <ai-typo as="p" variant="paragraph-04-regular" color="stratos-900">
      {{
        $t('search.datePicker.formattedDate', {
          month: currentMonth?.text,
          year,
        })
      }}
    </ai-typo>

    <button class="AiDatePicker-MonthYear-Button" @click="onNext">
      <ai-icon
        v-if="customProps.isDesktop && displayRightButton"
        class="AiDatePicker-MonthYear-Action"
        name="chevron-right"
        :size="12" />
    </button>
  </div>
</template>

<script setup lang="ts">
interface AiDatePickerMonthYearProps {
  customProps: Record<string, any>;
  month: number;
  months: { text: string; value: number }[];
  year: number;
  years: { text: string; value: number }[];
  instance: number;
  updateMonthYearMethod: (
    month: number,
    year: number,
    fromNav: boolean,
  ) => void;
}

const props = defineProps<AiDatePickerMonthYearProps>();

const updateMonthYear = (month: number, year: number, fromNav?: boolean) => {
  props.updateMonthYearMethod(month, year, fromNav || false);
};

const onNext = () => {
  let month = props.month;
  let year = props.year;

  if (props.month === 11) {
    month = 0;
    year = props.year + 1;
  } else {
    month += 1;
  }

  updateMonthYear(month, year);
};

const onPrev = () => {
  let month = props.month;
  let year = props.year;
  if (props.month === 0) {
    month = 11;
    year = props.year - 1;
  } else {
    month -= 1;
  }
  updateMonthYear(month, year);
};

const currentMonth = computed(() =>
  props.months.find(month => month.value === props.month),
);

const isLeftCustomComponent = computed(() => {
  return props.instance === 0;
});

const displayLeftButton = computed(() => {
  return isLeftCustomComponent.value || !props.customProps.doubleCalendar;
});

const displayRightButton = computed(() => {
  return !isLeftCustomComponent.value || !props.customProps.doubleCalendar;
});
</script>

<style lang="scss" scoped>
@use '@/assets/styles/utilities/constants';
@use '@/assets/styles/utilities/mq';

.AiDatePicker-MonthYear {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: constants.$margin-02;
  width: 100%;

  > p {
    flex-basis: 100%;
    text-align: center;
  }

  @media (mq.$upto-medium) {
    margin-top: constants.$margin-02;
  }
}

.AiDatePicker-MonthYear-Action {
  cursor: pointer;
}

.AiDatePicker-MonthYear-Button {
  @media (mq.$upto-medium) {
    display: none;
  }
}
</style>
