<template>
  <datepicker
    ref="datepickerRef"
    :auto-apply="autoApply"
    :close-on-clear-value="false"
    :disabled-dates="disabledDates"
    :enable-time-picker="false"
    :inline="true"
    :locale="locale"
    :max-date="maxRangeDate"
    :min-date="minRangeDate"
    :range="{ minRange: 1, maxRange: DATE_PICKER_MAX_RANGE }"
    :model-value="modelValue"
    :month-change-on-arrows="false"
    :month-change-on-scroll="false"
    :multi-calendars="count"
    :space-confirm="false"
    :start-date="new Date()"
    focus-start-date
    hide-offset-dates
    :config="{ keepActionRow: true }"
    month-name-format="long"
    no-swipe
    prevent-min-max-navigation
    @open="emitCalendarChanged"
    @update:model-value="updateValue"
    @internal-model-change="onInternalValueChange">
    <template #day="{ day, date }">
      <span v-if="day !== 0" class="dp__day" @click="onDayClicked(date)">
        {{ day }}
      </span>
    </template>
    <template
      #month-year="{ month, year, months, years, instance, updateMonthYear }">
      <ai-multi-date-picker-month-year
        :custom-props="customProps"
        :instance="instance!"
        :month="month!"
        :months="months!"
        :update-month-year-method="updateMonthYear!"
        :year="year"
        :years="years!" />
    </template>
    <template #action-row="{ internalModelValue }">
      <ai-multi-date-picker-action-row
        ref="multiDatePickerActionRow"
        :custom-props="customProps"
        :internal-model-value="internalModelValue"
        @clear-dates="clearDates"
        @select-date="confirmDate"
        @select-internal-dates="intervalDate" />
    </template>
  </datepicker>
</template>

<script lang="ts" setup>
import type { DatePickerInstance } from '@vuepic/vue-datepicker';
import datepicker from '@vuepic/vue-datepicker';
import { format } from 'date-fns';

import AiMultiDatePickerActionRow from '../AiMultiDatePickerActionRow.vue';
import AiMultiDatePickerMonthYear from '../AiMultiDatePickerMonthYear.vue';

import { DATE_PICKER_MAX_RANGE } from '~~/core';

type DesktopPickerProps = {
  modelValue: Date | Date[];
  customProps: Record<string, any>;
  autoApply?: boolean;
  minimumStayDuration?: number;
};
const props = withDefaults(defineProps<DesktopPickerProps>(), {
  autoApply: () => true,
  minimumStayDuration: undefined,
});

type DesktopPickerEmits = {
  (event: 'update:modelValue', value: Date | Date[]): void;
  (event: 'clearDate'): void;
  (event: 'selectDate'): void;
  (event: 'calendar-changed', value: DatePickerInstance | undefined): void;
};
const emit = defineEmits<DesktopPickerEmits>();

const { locale } = useI18n();

const multiDatePickerActionRow = ref<typeof AiMultiDatePickerActionRow>();

const updateValue = (newValue: Date | Date[]) => {
  emit('update:modelValue', newValue);
};

const count = ref(13);

// Since it's a ref, it must be named exactly the same as the ref.
// It means we CANT shorthand the affectation to the customProps variable
const datepickerRef = ref<DatePickerInstance>();
const lastInternalValue = ref<Date[]>();

const clearDates = () => {
  if (!datepickerRef.value) return;
  updateValue([]);
  selectDate.value = [];
};

const confirmDate = () => {
  if (!datepickerRef.value) return;

  datepickerRef.value.selectDate();
  emit('selectDate');
};

const selectDate = ref<Date[]>([]);

const intervalDate = (dates: Date[]) => {
  selectDate.value = dates;
};

const maxRangeDate = computed(() => {
  if (!selectDate.value.length) {
    return '';
  }
  const date = new Date(selectDate.value[0]);

  date.setDate(date.getDate() + DATE_PICKER_MAX_RANGE);
  return date;
});

const minRangeDate = computed(() => {
  if (!selectDate.value.length) {
    return new Date();
  }

  const today = new Date();
  const date = new Date(selectDate.value[0]);

  date.setDate(date.getDate() - DATE_PICKER_MAX_RANGE);
  return date < today ? today : date;
});

const startDate = computed(() => {
  const defaultStartDate = new Date();

  if (!props.modelValue) {
    return defaultStartDate;
  }

  const firstDate = Array.isArray(props.modelValue)
    ? props.modelValue[0]
    : props.modelValue;

  return firstDate ?? defaultStartDate;
});

const openDatePicker = () => {
  if (!datepickerRef.value) return;

  datepickerRef.value.openMenu();
  emitCalendarChanged();
};

const disabledDates = computed(() => {
  let internalValue = toRaw(multiDatePickerActionRow.value?.internalValue);

  if (Array.isArray(internalValue)) {
    internalValue = internalValue[0];
  }

  if (!internalValue || !props.minimumStayDuration) return;

  const dates = [];

  for (let i = 1; i < props.minimumStayDuration; i++) {
    const futureDate = new Date(internalValue);
    const previousDate = new Date(internalValue);

    futureDate.setDate(futureDate.getDate() + i);
    previousDate.setDate(previousDate.getDate() - i);

    dates.push(futureDate, previousDate);
  }

  return dates;
});

const datepickerInternalValue = useDatepickerInternalValue();

const onDayClicked = (date: Date) => {
  const currentDates = datepickerInternalValue.dates.value;

  if (
    !Array.isArray(currentDates) ||
    (Array.isArray(currentDates) && currentDates.length < 1)
  )
    return;

  if (format(date, 'yyyy-MM-dd') === format(currentDates[0], 'yyyy-MM-dd')) {
    nextTick(() => {
      requestAnimationFrame(() => {
        datepickerInternalValue.updateDate([]);
        datepickerRef.value?.clearValue();
      });
    });
  }
};

const onInternalValueChange = (newInternalValue: Date[]) => {
  if (!lastInternalValue.value) {
    lastInternalValue.value = newInternalValue;
    emitCalendarChanged();

    return;
  }

  if (!newInternalValue) {
    lastInternalValue.value = undefined;
  }

  if (
    lastInternalValue.value &&
    newInternalValue[0] && // Start date selected
    !newInternalValue[1] // End date not selecte
  ) {
    const formattedStartDate =
      newInternalValue[0].getDate() +
      '-' +
      newInternalValue[0].getMonth() +
      '-' +
      newInternalValue[0].getFullYear();

    const formattedLastInternalStartDate =
      lastInternalValue.value[0].getDate() +
      '-' +
      lastInternalValue.value[0].getMonth() +
      '-' +
      lastInternalValue.value[0].getFullYear();

    if (formattedLastInternalStartDate === formattedStartDate) {
      datepickerRef.value?.clearValue();
    }
  }

  lastInternalValue.value = newInternalValue;

  emitCalendarChanged();
  if (lastInternalValue.value?.length === 2) {
    const selectDateRef = multiDatePickerActionRow.value?.selectDateRef;
    setTimeout(() => {
      selectDateRef?.$el?.focus();
    }, 100);
  }
};

const emitCalendarChanged = () => {
  nextTick(() => {
    emit('calendar-changed', datepickerRef?.value);
  });
};

defineExpose({
  datepicker: datepickerRef,
  openDatePicker,
});
</script>
